<template>
  <el-dialog
    class="dailog"
    :visible.sync="value"
    width="960px"
    :showClose="false"
    :close-on-click-modal="false"
  >
    <div class="dailog-main" v-html="$t('privacyPolicy')"></div>
    <span slot="footer">
      <el-button class="footer-btn" type="primary" size="small" @click="handleSubmit">{{ $t('confirm') }}</el-button>
      <el-button class="footer-btn" type="primary" plain size="small" @click="handleClose">{{ $t('cancel') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'privacyPolicy',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Boolean,
  },
  data() {
    return {
      dialogVisible: true,
    }
  },
  methods: {
    handleClose() {
      this.$emit('change', false)
    },
    handleSubmit() {
      this.$emit('submit', true)
      this.handleClose()
    },
  },
}
</script>

<style scoped>
.dailog-main {
  word-break: break-word;
}
</style>
