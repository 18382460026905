<template>
  <div class="login">
    <div class="center">
      <el-card class="login-card" v-show="!retrieve">
        <div class="left">
          <img class="logo" src="@/assets/images/logo.png" alt />
        </div>
        <div class="right">
          <div class="login-title">{{ isLAN ? $t('title-lan') : $t('title') }}</div>
          <el-form ref="form" :model="form" :rules="rules" @keyup.enter.native="login()">
            <el-form-item prop="username">
              <el-input
                class="login-input"
                prefix-icon="el-icon-s-custom"
                v-model="form.username"
                :placeholder="$t('login.usernamePla')"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password" style="margin-bottom: 12px">
              <el-input
                class="login-input"
                prefix-icon="el-icon-lock"
                v-model="form.password"
                :placeholder="$t('login.passwordPla')"
                show-password
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="forget">
            <span style="float:right" v-if="!isLAN" @click="forgetPassword">{{ $t('login.forgetPassword') }}</span>
          </div>
          <el-button
            type="primary"
            class="w100"
            @click="login"
            :disabled="!privacyPolicyChecked"
            :loading="axiosLoading"
            >{{ $t('login.login') }}</el-button
          >
          <div class="lan-wrap">
            <el-dropdown @command="languageCommand">
              <span class="el-dropdown-link" style="cursor: pointer;">
                {{ languageOptions[language] }}
                <i class="el-icon-caret-bottom" style="color: #6b489d;"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(value, key) in languageOptions"
                  :key="key"
                  :command="key"
                  :disabled="language == key"
                  >{{ value }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <div class="footerTip">
            <div class="fl">
              <el-checkbox v-model="privacyPolicyChecked"></el-checkbox>
              <el-button type="text" @click="privacyPolicy" style="margin-left:5px">{{
                $t('login.privacyPolicy')
              }}</el-button>
            </div>
            <div class="fl-r">
              <el-button type="text" @click="feedback">{{ $t('login.feedback') }}</el-button>
              <el-button type="text" @click="documents">{{ $t('login.documents') }}</el-button>
            </div>
          </div>
        </div>
      </el-card>
      <el-card class="retrievePassword" v-show="retrieve">
        <div class="title">{{ $t('login.forgetPassword') }}</div>
        <div class="text">{{ $t('login.retPsdDescribe') }}</div>
        <el-form ref="retrieveForm" :model="retrieveForm" :rules="retrieveRules">
          <el-form-item prop="email">
            <el-input v-model="retrieveForm.email" :placeholder="$t('login.emailPla')"></el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input
              v-model="retrieveForm.code"
              :placeholder="$t('login.verifyCodePla')"
              style="width: 280px"
            ></el-input>
            <valid-code
              ref="validCode"
              v-if="retrieve"
              :value.sync="validCode"
              style="display: inline"
            ></valid-code>
          </el-form-item>
        </el-form>
        <el-button type="primary" class="sure" @click="submit">{{
          $t('login.determine')
        }}</el-button>
        <span class="back" @click="retrieve = false">{{ $t('login.rtLogin') }}</span>
      </el-card>
    </div>
    <!-- copyright -->
    <div class="copyright">
      {{ $t('copyright') }}
    </div>
    <!-- cookie提示 -->
    <el-drawer
      direction="btt"
      size="auto"
      :withHeader="false"
      :visible.sync="drawerVisible"
      :before-close="cookieClose"
    >
      <div class="cookie-content">
        <div>{{ $t('login.cookieTitle') }}</div>
        <div>{{ $t('login.cookieContent') }}</div>
        <div class="cookie-footer">
          <el-button @click="noPrompt">{{ $t('noPrompt') }}</el-button>
          <el-button type="primary" @click="cookieClose">{{ $t('ok') }}</el-button>
        </div>
      </div>
    </el-drawer>
    <!-- 隐私政策 -->
    <privacy-policy v-model="privacyPolicyShow" @submit="(val) => (checked = val)"></privacy-policy>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import privacyPolicy from './privacyPolicy'
import validCode from './validCode.vue'
import { forgetPassword } from '@/api/app.js'

export default {
  name: 'Login',
  components: {
    validCode,
    privacyPolicy,
  },
  data() {
    // 邮箱验证
    var validateEmail = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.emailIsCorrectTip')))
        this.retrieveData = false
      } else {
        if (value !== '') {
          var reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/
          if (!reg.test(value)) {
            callback(new Error(this.$t('login.emailIsValidTip')))
            this.retrieveData = false
          } else {
            this.retrieveData = true
          }
        }
        callback()
      }
    }
    // 验证码验证
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error(this.$t('login.verifyCodeRequireTip')))
        this.retrieveData = false
      } else {
        if (value !== '') {
          var code = this.validCode
          if (value.toLowerCase() !== code.toLowerCase()) {
            this.$refs.validCode.refreshCode()
            callback(new Error(this.$t('login.verifyCodeIsCorrectTip')))
            this.retrieveData = false
          } else {
            this.retrieveData = true
          }
        }
        callback()
      }
    }
    return {
      drawerVisible: this.$store.state.memory.loginCookieTipShow,
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: {
          required: true,
          message: this.$t('login.usernameReq'),
          trigger: 'blur',
        },
        password: {
          required: true,
          message: this.$t('login.passwordReq'),
          trigger: 'blur',
        },
      },
      // 策略
      privacyPolicyChecked: true,
      privacyPolicyShow: false,
      // 找回密码
      retrieve: false,
      validCode: '',
      retrieveForm: {
        email: '',
        code: '',
      },
      retrieveRules: {
        email: {
          required: true,
          validator: validateEmail,
          trigger: 'blur',
        },
        code: {
          required: true,
          validator: validateCode,
          trigger: 'blur',
        },
      },
    }
  },
  computed: {
    ...mapState('account', ['asyncRouterMap']),
    ...mapState('memory', ['language']),
    ...mapState('session', ['axiosLoading', 'isLAN']),
    ...mapGetters('dist', ['languageOptions']),
  },
  methods: {
    login() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let { ...params } = this.form
          this.$store
            .dispatch('account/Login', params)
            .then(res => {
              this.$store.commit('memory/SET_SIDEBAR_SITE', []) // 清除站点
              let firstCompliantUrl = res.data.result.rows[0].resources[0].children.length
                ? res.data.result.rows[0].resources[0].children[0].rsUrl
                : res.data.result.rows[0].resources[0].rsUrl
              console.log('登录', firstCompliantUrl)
              let url = this.$route.query.router || firstCompliantUrl
              url = url == 'home' ? '/' : '/' + url
              this.$router.replace(url)
            })
            .catch(() => {})
        }
      })
    },
    languageCommand(command) {
      this.$store.commit('memory/SET_LANGUAGE', command)
    },
    feedback() {
      this.$alert(this.$t('login.feedbackInfo'), {
        confirmButtonText: this.$t('iKnow'),
        center: true,
        dangerouslyUseHTMLString: true,
      })
    },
    documents() {
      window.open('https://www.aledevice.com/site/download')
    },
    noPrompt() {
      this.drawerVisible = false
      this.$store.commit('memory/SET_LOGIN_COOKIE_TIP_SHOW', false)
    },
    cookieClose() {
      this.drawerVisible = false
    },
    // 策略
    privacyPolicy() {
      this.privacyPolicyShow = true
    },
    //忘记密码
    forgetPassword() {
      this.retrieve = true
      this.retrieveForm.code = ''
    },
    // 找回密码提交
    submit() {
      this.$refs['retrieveForm'].validate((valid) => {
        if (valid) {
          //忘记密码
          forgetPassword(this.retrieveForm.email)
            .then((res) => {
              this.$message.success(res.data.message)
              this.retrieve = false
            })
            .catch(() => {})
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.font-cmedium {
  font-family: c-Medium;
  font-size: 14px;
  color: #383038;
  font-weight: normal;
}
.login {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #4d5576;
  background: url('../../assets/images/bg.jpg') no-repeat center center / 100% auto;
  ::v-deep .el-message-box--center {
    width: 750px;
  }
  ::v-deep .el-dropdown {
    border-right: 1px solid #fff;
  }
  .copyright {
    position: fixed;
    bottom: 10px;
    width: 100%;
    color: #ccc;
    margin: 0 auto;
    text-align: center;
  }
  .center {
    width: 750px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .login-card {
      border: 0;
      border-radius: 0;
      background-color: transparent;
    }
    .login-card.country-show {
      .right {
        padding: 88px 50px 50px 50px;
        .login-title {
          top: 38px;
        }
      }
    }
    ::v-deep .el-input__inner:hover {
      border-color: #999;
    }
    ::v-deep .el-card__body {
      padding: 0;
      width: 750px;
    }
    .login-card ::v-deep {
      .el-card__body {
        display: flex;
      }
    }
    .left {
      display: inline-block;
      width: 250px;
      vertical-align: top;
      position: relative;
      background-color: #6b489d;
      opacity: 0.8;
      .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
      }
      div,
      span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      div {
        width: 50px;
        height: 50px;
        background-color: yellow;
        border-radius: 50%;
        top: 70px;
      }
    }
    .right {
      background-color: #ffffff;
      position: relative;
      vertical-align: top;
      padding: 130px 50px 12px 50px;
      flex: 1;
      .login-title {
        position: absolute;
        top: 72px;
        left: 0;
        width: 100%;
        text-align: center;
        font-weight: bold;
        // font-size: 1.25em;
        @extend .font-cmedium;
        font-size: 22px;
      }
      ::v-deep .el-form-item__content {
        width: 400px;
      }
      ::v-deep .el-select {
        width: 400px;
      }
      .forget {
        cursor: pointer;
        margin-bottom: 42px;
        -moz-user-select: none;
        /*支持Firefox浏览器*/
        -webkit-user-select: none;
        /*支持Chrome，Opera，Safari等浏览器*/
        -ms-user-select: none;
        /*支持IE浏览器*/
        user-select: none;
      }

      .w100 {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    .retrievePassword {
      height: 455px;
      width: 750px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10000;
      box-sizing: border-box;
      .title {
        color: rgb(46, 43, 43);
        font-size: 40px;
        text-align: center;
        margin-top: 40px;
      }
      .text {
        color: #ccc;
        font-size: 20px;
        text-align: center;
        margin-bottom: 50px;
      }
      ::v-deep .el-form-item__content {
        width: 400px;
        margin: 0 auto;

        .c {
          display: inline;
        }
      }
      .sure {
        width: 400px;
        margin-left: 175px;
        margin-top: 25px;
      }
      .back {
        cursor: pointer;
        display: block;
        text-decoration: none;
        width: 300px;
        margin: 10px auto;
        text-align: center;
      }
    }
  }
}
@media screen and (max-aspect-ratio: 1920/1080) {
  .login {
    background-size: auto 100%;
  }
}
.lan-wrap {
  text-align: center;
}
.footerTip ::v-deep {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-button--text {
    color: #409eff;
  }
  .el-button--text:focus,
  .el-button--text:hover {
    color: initial;
    border-color: transparent;
    background-color: initial;
  }
  .el-checkbox__label,
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #409eff;
    border-color: #409eff;
  }
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: #409eff;
  }
}
// cookie
::v-deep .el-drawer {
  min-height: 150px; // 兼容ie
  .cookie-content {
    margin: 30px 0;
    padding-left: 85px;
    padding-right: 85px;
    .cookie-footer {
      text-align: right;
    }
  }
}
</style>
